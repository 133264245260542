import React from 'react';

class CommentForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = { comment: '' };
  }

  handleCommentChange = (e) => {
    this.setState({ comment: e.target.value });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    var comment = this.state.comment.trim();
    if (!comment) {
      return;
    }
    this.props.onCommentSubmit({ comment: comment });
    this.setState({ comment: '' });
  }
  render() {

    if (!this.props.filter) {
      if (this.props.comment_type == "ASSESSMENT") {
        var comment_on = <span>assessment</span>;
      } else if (this.props.comment_type == "CONNECT_WORKING_SET") {
        var comment_on = <span>working set</span>;
      }
    } else {
      var comment_on = <span className="filter-comments btn btn-xs active static">{this.props.filter}</span>
    }

    return (
      <div>
        <form className="commentForm" onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="comment">Comment on {comment_on}</label>
            <textarea className="form-control" id="comment" name="comment" rows="5" placeholder="Say something..."
              value={this.state.comment}
              onChange={this.handleCommentChange}></textarea>
          </div>
          <input className="btn btn-success" style={{ float: 'right', marginBottom: '10px' }} type="submit" value="Add Comment" />
        </form>
      </div>
    );
  }
};

export default CommentForm;
