import React from "react";
import WorkingSetsTable from './WorkingSetsTable';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import ShowArchivedWorkingSets from "./ShowArchivedWorkingSets"


const archivedFilter = (state = {}, action) => {
    switch (action.type) {
        case 'SHOW_ARCHIVED':
            return { show_archived: true }
        default:
            return {};
    }
}

const store = createStore(archivedFilter);

class ListWorkingSets extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            current_user: props.current_user,
            ws_status_names: props.ws_status_names,
            form_authenticity_token: props.form_authenticity_token,
            users_map: props.users_map
        }
    }

    render() {
        return (
            <div id="listworkingsets" style={{ width: "80%", minWidth: "1200px" }}>
                <Provider store={store}>
                    {this.state.current_user.is_redlist === true && <ShowArchivedWorkingSets />}
                    <WorkingSetsTable current_user={this.state.current_user} users_map={this.state.users_map} store={store}
                        ws_status_names={this.state.ws_status_names} form_authenticity_token={this.state.form_authenticity_token} />
                </Provider>
            </div>)
    }

}

export default ListWorkingSets;

