import React from 'react';
import Comment from './Comment'

class CommentList extends React.Component {
  render() {
    var onCommentDelete = this.props.onCommentDelete;
    var current_user_id = this.props.current_user_id;
    var current_user_is_admin = this.props.current_user_is_admin;
    var locked = this.props.locked;
    var commentNodes = this.props.data.map(function (comment) {
      return (
        <Comment key={comment.id} id={comment.id} email={comment.email} user_id={comment.user_id} time_ago={comment.time_ago} filter={comment.filter} current_user_id={current_user_id} current_user_is_admin={current_user_is_admin} onCommentDelete={onCommentDelete} locked={locked}>
          {comment.comment}
        </Comment>
      );
    });
    return (
      <div className="commentList">
        {commentNodes}
      </div>
    );
  }
}

export default CommentList;
