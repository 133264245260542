import React from 'react';

class WSBrowserBct extends React.Component {
  componentDidMount = () => {
    // Nothing
  }
  render() {
    if (this.props.assessment.id != 0) {
      return (
        <div>
          <ol className="breadcrumb">
            <li><a onClick={this.props.onDeselectWs}>All</a></li>
            <li><a onClick={this.props.onDeselectAssessment}>{this.props.ws.name}</a></li>
            <li className="active">{this.props.assessment.id}</li>
          </ol>
        </div>
      );
    } else if (this.props.ws.name != "") {
      return (
        <div>
          <ol className="breadcrumb">
            <li><a onClick={this.props.onDeselectWs}>All</a></li>
            <li className="active">{this.props.ws.name}</li>
          </ol>
        </div>
      );
    } else {
      return (
        <div>
          <ol className="breadcrumb">
            <li className="active">All</li>
          </ol>
        </div>
      );
    }
  }
};

export default WSBrowserBct;
