import React from 'react';

class WSBrowserWSTable extends React.Component {
  componentDidMount = () => {
    var url;

    if (this.props.forumMode) {
      url = '/api/working_sets?forum_id=' + this.props.forumId;
    } else {
      url = this.props.apiBase + '/workingsets?key=' + this.props.apiToken;
    }

    var table = $('table#ws-table').DataTable({
      ajax: {
        url: url,
        dataSrc: 'working_sets'
      },
      columns: [
        { data: 'working_setid' },
        { data: 'name' }
      ],
      columnDefs: [
        {
          'targets': [0],
          'visible': false
        }
      ]
    });

    // This function has to be defined outside to be accessible in the event
    // handler.
    onSelectWS = this.props.onSelectWS

    $('table#ws-table tbody').on('click', 'tr', function () {
      var ws_id = table.row(this).data().working_setid,
        ws_name = table.row(this).data().name,
        ws = { id: ws_id, name: ws_name };
      onSelectWS(ws);
    });
  }
  render() {
    return (
      <div className="ws-browser-ws-table">
        <table id="ws-table" width="100%" className="stripe display">
          <thead>
            <tr>
              <th>Working set ID</th>
              <th>Working set name</th>
            </tr>
          </thead>
        </table>
      </div>
    );
  }
};

export default WSBrowserWSTable;
