import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import dayjs from 'dayjs';
import WorkingSetExportButton from './WorkingSetExportButton'
import "../src/application.scss"

const filterCaseInsensitive = ({ id, value }, row) =>
    row[id] ? row[id].toString().toLowerCase().includes(value.toLowerCase()) : true

class SisWorkingSetTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            main_files: props.main_files,
            class_files: props.class_files,
            other_files: props.other_files,
            form_authenticity_token: props.form_authenticity_token
        };
    }

    render() {
        return (
            <ReactTable
                data={this.state.data}
                defaultFilterMethod={filterCaseInsensitive}
                filterable
                columns={[
                    {
                        Header: "Working set",
                        accessor: "name",
                        canFilter: true,
                    },
                    {
                        Header: "Description",
                        accessor: "description"
                    },
                    {
                        Header: "Created date",
                        accessor: "created_date",
                        Cell: row => (dayjs(row.value).format('D MMM, YYYY')),
                    },
                    {
                        Header: 'Most recent published',
                        accessor: 'most_recent_published',
                        Cell: row => (row.value.toString()),
                    },
                    {
                        Header: 'Creator',
                        accessor: 'creator'
                    },
                    {
                        Header: 'Assessment type',
                        accessor: 'assessment_type',
                    },
                    {
                        Header: 'Relationship',
                        accessor: 'relationship',
                    },
                    {
                        Header: 'Regions',
                        accessor: 'regions',
                        Cell: row => (JSON.stringify(row.value)),
                    },
                    {
                        Header: '',
                        id: 'export_btn',
                        accessor: row => (row),
                        Cell: row => (
                            <WorkingSetExportButton main_files={this.state.main_files}
                                class_files={this.state.class_files} other_files={this.state.other_files}
                                working_set={row.value} form_authenticity_token={this.state.form_authenticity_token} />
                        ),
                    }
                ]}
                getTdProps={(state, rowInfo, column, instance) => {
                    if (rowInfo && column.Header === '') {
                        return {
                            style: {
                                overflow: 'visible',
                                whiteSpace: 'normal'
                            }
                        };
                    } else {
                        return {};
                    }
                }}
            />
        );
    }
}

export default SisWorkingSetTable;
