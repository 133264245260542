import React from 'react';
import CommentDeleteButton from './CommentDeleteButton'

class Comment extends React.Component {
  render() {
    var onCommentDelete = this.props.onCommentDelete;
    var id = this.props.id;
    var user_id = this.props.user_id;
    var current_user_id = this.props.current_user_id;
    var current_user_is_admin = this.props.current_user_is_admin;
    var locked = this.props.locked;

    var deleteButton = null
    if ((current_user_is_admin || current_user_id == user_id) && !locked) {
      deleteButton = <CommentDeleteButton onCommentDelete={onCommentDelete} id={id} />;
    }

    return (
      <div className="comment">
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default" style={{ marginBottom: '30px' }}>
              <div className="panel-heading" style={{ height: "30px", fontSize: "12px" }}>
                <div style={{ float: "left", marginTop: "-4px" }}>
                  <strong>{this.props.email}</strong>
                </div>
                <div style={{ float: "right", marginTop: "-4px" }}>
                  <span className="label label-danger" style={{ fontSize: "80%" }}>{this.props.filter}</span>
                </div>
              </div>
              <div className="panel-body" style={{ fontSize: "12px" }}>
                {this.props.children}
              </div>
              <div className="panel-footer" style={{ height: "25px", padding: "2px 15px" }}>
                <div style={{ float: "left" }}>
                  <span className="text-muted" style={{ fontSize: "11px" }}>{"Commented " + this.props.time_ago + " ago."}</span>
                </div>
                <div style={{ float: "right" }}>
                  {deleteButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Comment;
