import React from 'react';

class TaxonFinderSearch extends React.Component {
  componentDidMount = () => {
    select_widget = $('select.main-select').select2({
      placeholder: "Enter a search term",
      ajax: {
        url: this.props.apiBase + '/taxonomy/search?key=' + this.props.apiToken,
        type: 'POST',
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            query: params.term,
            level: 0
          };
        },
        processResults: function (data, params) {
          var results;
          results = data.results.map(function (result) {
            return {
              id: result.taxonid,
              text: result.friendly_name
            };
          });
          return {
            results: results
          };
        },
        cache: true
      },
      minimumInputLength: 3,
      allowClear: true
    });

    // When a selection is made, let your parent know
    $('select.main-select').on("change", function (e) {
      data = $('select.main-select').select2('data')

      if (data.length == 0) {
        id = "0"
      } else {
        id = data[0].id
      }

      this.props.onSearchSelect(id);
    }.bind(this));
  }
  render() {
    return (
      <select className="main-select" style={{ width: '400px' }}></select>
    );
  }
};

export default TaxonFinderSearch;
