// To include this component:
// = react_component('TaxonFinder', {:apiToken => @token, :apiBase => GlobalConstants::SIS_API_BASE})

import React from 'react';
import TaxonFinderSearch from './TaxonFinderSearch'
import Axios from "axios"
import TaxonFinderTree from "./TaxonFinderTree"

class TaxonFinder extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      taxon: null,
      parents: null,
      children: null
    };
  }

  handleSearchSelect = (selected_id) => {
    if (selected_id == 0) {
      // Search cleared!
      this.setState({
        taxon: null,
        parents: null,
        children: null
      })
    } else {
      Axios.get(this.props.apiBase + "/taxonomy/browse/" + selected_id + "?key=" + this.props.apiToken)
        .then((response) => {
          this.setState({
            taxon: response.data.taxon,
            parents: response.data.parents,
            children: response.data.children
          });
        }).catch((error) => {
          console.error(error);
        });
    }
  }
  render() {
    return (
      <div className="taxon-finder">
        <h4>Taxon Finder</h4>
        <TaxonFinderSearch onSearchSelect={this.handleSearchSelect} apiToken={this.props.apiToken} apiBase={this.props.apiBase} />
        <TaxonFinderTree onSearchSelect={this.handleSearchSelect} data={this.state} />
      </div>
    );
  }
};

export default TaxonFinder;
