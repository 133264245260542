import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import dayjs from 'dayjs';
import "../src/application.scss"
import WorkingSetActions from './WorkingSetActions'
import startCase from "lodash/startCase"
import debounce from "lodash/debounce"
import Axios from "axios"
import Select from 'react-select'
import { connect } from 'react-redux';


const WorkingSetEmpty = () => (
    <div className="alert alert-warning alert-dismissible" role="alert">
        <button className="close" aria-label="Close" data-dismiss="alert" type="button">
            <span aria-hidden="true" dangerouslySetInnerHTML={{ __html: '\u00D7' }} />
        </button>
        <i className="fa fa-info-circle" />
        You do not currently have any working sets. Click 'New Working Set' to get started.
    </div>
)

const filterCaseInsensitive = ({ id, value }, row) =>
    row[id] ? row[id].toString().toLowerCase().includes(value.toLowerCase()) : true

const sufixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

const getBytes = (bytes) => {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return !bytes && '0 Bytes' || (bytes / Math.pow(1024, i)).toFixed(2) + " " + sufixes[i];
};

const UploadedBy = (props) => (
    <React.Fragment>
        {props.working_set.uploaded_by}
        {
            (props.current_user.is_redlist === true) &&
            <div className="collapse" id={`reassign${props.working_set.id}`}>
                <React.Fragment>
                    <form method="POST" action="/change_user">
                        <input name="authenticity_token" type="hidden" value={props.form_authenticity_token} />
                        <input name="id" type="hidden" value={props.working_set.id} />
                        <div className="input-group">
                            <Select options={props.users_map} className="assing-user" style={{ width: "200px" }} name="user_id" />
                            <span className="input-group-btn">
                                <button className="btn btn-sm" type="submit" >Assign</button>
                            </span>
                        </div>
                    </form>
                </React.Fragment>
            </div>
        }
    </React.Fragment>
)

const WorkingSetIcons = (props) => {
    return (
        <React.Fragment>
            {
                (props.working_set.hasFileLevelValidationErrors) &&
                <i className="fa fa-file validation-error" data-tooltip="tooltip" title="File-level validation errors exist!" style={{ paddingLeft: "5px" }}></i>
            }
            {(props.working_set.hasFileLevelValidationWarnings) &&
                <i className="fa fa-file validation-warning" data-tooltip="tooltip" title="File-level validation warnings exist!" style={{ paddingLeft: "5px" }}></i>
            }

            {(props.working_set.hasRecordLevelValidationErrors) &&
                <i className="fa fa-database validation-error" data-tooltip="tooltip" title="Record-level validation errors exist!" style={{ paddingLeft: "5px" }} ></i>
            }
            {(props.working_set.hasRecordLevelValidationWarnings) &&
                <i className="fa fa-database validation-warning" data-tooltip="tooltip" title="Record-level validation warnings exist!" style={{ paddingLeft: "5px" }} ></i>
            }

            {(props.working_set.hasTaxonomyCheckErrors == true) &&
                <i className="fa fa-paw validation-error" data-tooltip="tooltip" title="Taxonomy check errors exist!" style={{ paddingLeft: "5px" }} ></i>
            }
            {(props.working_set.hasTaxonomyCheckErrors == false) &&
                <i className="fa fa-paw" data-tooltip="tooltip" title="Taxonomy check passed!" style={{ paddingLeft: "5px", color: "green" }} ></i>
            }
        </React.Fragment>
    )
}

const WorkingSetName = (props) => {
    let working_set_name = '';
    if (props.working_set.being_validated === true || props.working_set.needs_revalidating === true) {
        working_set_name = props.working_set.name;
    } else {
        const href = `/working_sets/${props.working_set.id}/show_working_set`;
        working_set_name = <a className='ws-name' href={href}>{props.working_set.name}</a>
    }
    return (
        <React.Fragment>
            {working_set_name}
            <div className="icons-list">
                <WorkingSetIcons working_set={props.working_set} />
                <span className='file-size'>
                ( { getBytes(props.working_set.properties.file_size) } )
                </span>
            </div>
            {props.working_set.archived === true && <b>ARCHIVED</b>}
            {(props.working_set.needs_revalidating === true) &&
                <React.Fragment>
                    <br />
                    <br />
                    <div className="alert alert-warning" style={{ fontSize: "12px", display: "inline-block", marginLeft: "10px strong Important!" }}>This working set requires re - validating.Press "Validate taxonomy" to continue.</div>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

const RegionalScope = (props) => {
    let working_set = props.working_set;
    let regionName = 'no region selected';

    if (working_set.regional_scope) {
        regionName = working_set.regional_scope["name"];
    };

    return (
        <React.Fragment>
            <div class='regional-scope'>
                {regionName}
            </div>
        </React.Fragment>
    )
}

class WorkingSetsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current_user: props.current_user,
            ws_status_names: props.ws_status_names,
            working_sets: [],
            form_authenticity_token: props.form_authenticity_token,
            users_map: props.users_map,
            pages: -1,
            loading: true,
            filtered: []
        };
        this.fetchDataWithDebounce = debounce(this.fetchData, 1000);
    }

    fetchStrategy = (state, instance) => {
        if (this.filtering) {
            return this.fetchDataWithDebounce(state, instance);
        } else {
            return this.fetchData(state, instance);
        }
    }

    onFilteredChange = (column, value) => {
        this.filtering = true;
        this.setState({ filtered: column });
    }

    fetchData = (state, instance) => {
        this.filtering = false;
        this.setState({ loading: true });
        Axios.get('working_sets/list', {
            params: {
                page: state.page,
                page_size: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        }).then((res) => {
            this.setState({
                working_sets: res.data.rows,
                pages: res.data.pages,
                loading: false
            });
        }).catch((error) => {
            console.error(error);
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.state_archived !== this.props.state_archived) {
            if (this.props.state_archived.hasOwnProperty('show_archived')) {
                const filters = [...this.state.filtered, { id: 'show_archived', value: '1' }]
                this.setState({ filtered: filters });
            } else {
                const filters = [...this.state.filtered]
                filters.splice(filters.findIndex(function (i) {
                    return i.id === "show_archived";
                }), 1);
                this.setState({ filtered: filters })
            }
        }
    }

    render() {
        return (
            <ReactTable
                data={this.state.working_sets}
                defaultFilterMethod={filterCaseInsensitive}
                filterable
                filtered={this.state.filtered}
                columns={[
                    {
                        Header: "Name",
                        id: 'name',
                        accessor: row => (row),
                        Cell: row => (<WorkingSetName working_set={row.value} />),
                        minWidth: 200
                    },
                    {
                        Header: "Regional Scope",
                        id: "region",
                        accessor: row => (row),
                        sortable: false,
                        filterable: false,
                        width: 150,
                        Cell: row => (<RegionalScope working_set={row.value} />)
                    },
                    {
                        Header: "User",
                        id: 'user',
                        accessor: row => (row),
                        sortable: false,
                        filterable: false,
                        minWidth: 150,
                        Cell: row => (<UploadedBy working_set={row.value} current_user={this.state.current_user}
                            users_map={this.state.users_map} form_authenticity_token={this.state.form_authenticity_token} />)
                    },
                    {
                        Header: 'Upload date',
                        accessor: 'created_at',
                        sortable: true,
                        width: 120,
                        Cell: row => (dayjs(row.value).format('D MMM, YYYY')),
                    },
                    {
                        Header: 'Status',
                        accessor: 'html_print_status',
                        Cell: row => (<div dangerouslySetInnerHTML={{ __html: row.value }} />),
                        sortable: false,
                        minWidth: 170,
                        Filter: ({ filter, onChange }) => {
                            return (
                                <select
                                    value={filter ? filter.value : '-1'}
                                    onChange={event => onChange(event.target.value)}>
                                    <option value="-1">Select value</option>
                                    {this.state.ws_status_names.map((option, i) => (
                                        <option key={i} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            )
                        }
                    },
                    {
                        Header: 'Actions',
                        id: 'actions_btn',
                        accessor: row => (row),
                        sortable: false,
                        filterable: false,
                        Cell: row => (
                            <WorkingSetActions working_set={row.value} current_user={this.state.current_user} form_authenticity_token={this.state.form_authenticity_token}/>
                        ),
                    }
                ]}
                className='working-sets'
                getTrProps={(state, rowInfo, instance) => {
                    if (typeof rowInfo === 'undefined') {
                        return {};
                    }
                    const working_set = rowInfo.original;
                    let class_names = '';
                    if (working_set.archived === true && this.state.current_user.is_redlist === true) {
                        class_names += 'archive '
                    }
                    if (working_set.redlist_check === true && this.state.current_user.is_redlist === true) {
                        class_names += 'check '
                    }
                    if (working_set.being_validated === true || working_set.needs_revalidating === true) {
                        class_names += 'disabled'
                    }
                    return {
                        className: class_names
                    }
                }}
                getTdProps={(state, rowInfo, column, instance) => {
                    if (typeof rowInfo === 'undefined') {
                        return {};
                    }
                    const working_set = rowInfo.original;
                    switch (column.Header) {
                        case '':
                            let class_names = '';
                            if (this.state.current_user.is_redlist) {
                                if (working_set.archived === true) {
                                    class_names += 'archive ';
                                }
                                if (working_set.redlist_check === true) {
                                    class_names += 'check ';
                                }
                            }
                            if (working_set.being_validated === true || working_set.needs_revalidating === true) {
                                class_names += 'disabled';
                            }
                            return ({
                                className: class_names,
                            })
                        case 'Actions':
                            return {
                                className: 'action-buttons',
                                style: {
                                    overflow: 'visible',
                                    whiteSpace: 'normal',
                                    width: '200px'
                                }
                            }
                        case 'Name':
                            return {
                                style: {
                                    fontSize: '17px'
                                }
                            }
                        case 'Status':
                            return {
                                'data-filter': startCase(working_set.workflow_state)
                            }
                        case 'Upload date':
                            return {
                                'data-order': working_set.created_at
                            }
                        case 'User':
                            return {
                                style: {
                                    overflow: 'visible'
                                }
                            }
                        default:
                            return {};
                    }
                }}
                loading={this.state.loading}
                manual
                pages={this.state.pages}
                onFilteredChange={this.onFilteredChange}
                onFetchData={this.fetchStrategy}
                defaultSorted={[{id: "created_at", desc: true}]}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        state_archived: state
    };
}

export default connect(mapStateToProps)(WorkingSetsTable);
