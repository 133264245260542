// Example usage:
// = react_component('CommentBox', {:url => "/api/comments", :pollInterval => 60000, :record_id => @assessment.id, :current_user_id => current_user.id, :current_user_is_admin => current_user.admin?, :comment_type => 'ASSESSMENT', :filter => "", :locked => false})
import React from 'react';
import CommentForm from './CommentForm'
import Axios from "axios"
import CommentList from "./CommentList"

class CommentBox extends React.Component {

  constructor(props) {
    super(props);
    this.state = { data: [] };
  }

  loadCommentsFromServer = () => {
    Axios.get(this.props.url, {
      params: {
        record_id: this.props.record_id,
        filter: this.props.filter,
        comment_type: this.props.comment_type
      }
    }).then((response) => {
      this.setState({ data: response.data });
    }).catch((error) => {
      console.error(error);
    });
  }
  handleCommentSubmit = (comment) => {
    var comments = this.state.data;
    // Optimistically set an id on the new comment. It will be replaced by an
    // id generated by the server. In a production application you would likely
    // not use Date.now() for this and would have a more robust system in place.
    comment.id = Date.now();
    var newComments = comments.concat([comment]);
    this.setState({ data: newComments });
    Axios.post(this.props.url, {
      record_id: this.props.record_id,
      comment_type: this.props.comment_type,
      user_id: this.props.current_user_id,
      filter: this.props.filter,
      comment: comment
    }).then((response) => {
      this.setState({ data: response.data });
    }).catch((error) => {
      this.setState({ data: comments });
      console.error(error);
    });
  }
  handleCommentDelete = (data) => {
    var comments = this.state.data;
    Axios.delete(this.props.url, {
      params: {
        record_id: this.props.record_id,
        comment_type: this.props.comment_type,
        filter: this.props.filter,
        comment_id: data.comment_id
      }
    }).then((response) => {
      this.setState({ data: response.data });
    }).catch((error) => {
        this.setState({ data: comments });
        console.error(error);
      });
  }
  componentDidMount = () => {
    this.loadCommentsFromServer();
    setInterval(this.loadCommentsFromServer, this.props.pollInterval);
  }
  render() {
    var commentForm;
    if (this.props.locked) {
      commentForm = <div style={{ paddingBottom: "10px" }}>Comments are locked.</div>
    } else {
      commentForm = <CommentForm filter={this.props.filter} comment_type={this.props.comment_type} onCommentSubmit={this.handleCommentSubmit} />
    }

    return (
      <div className="commentBox">
        {commentForm}
        <CommentList data={this.state.data} onCommentDelete={this.handleCommentDelete} current_user_id={this.props.current_user_id} current_user_is_admin={this.props.current_user_is_admin} locked={this.props.locked} />
      </div>
    );
  }
};


export default CommentBox;
