import React from 'react';

class CommentDeleteButton extends React.Component {
  handleDelete = (e) => {
    e.preventDefault();
    var comment_id = this.props.id;
    this.props.onCommentDelete({ comment_id: comment_id });
  }
  render() {
    return (
      <div className="commentDeleteButton">
        <form onSubmit={this.handleDelete}>
          <button className="btn btn-link" style={{ fontSize: '18px', padding: '0px', color: '#D81E05', marginTop: "-4px" }} type="submit" value="Delete Comment">
            <i className="fa fa-trash"></i>
          </button>
        </form>
      </div>
    );
  }
};

export default CommentDeleteButton;
