import React from 'react';
import axios from 'axios';

class LoginBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      form_action: '/login',
      username_name: 'username',
      username_placeholder: 'Username',
      password_name: 'password',
      form_on_submit: this.handleSisSubmit
    };
  }
  handleUsernameChange = (e) => {
    $(e.target).parent().parent().find(".form-group").removeClass("has-error");
    this.setState({ username: e.target.value });
  }
  handlePasswordChange = (e) => {
    $(e.target).parent().parent().find(".form-group").removeClass("has-error");
    this.setState({ password: e.target.value });
  }
  handleAccountChange = (e) => {
    // This is hacky. Radio boxes shouldn't work like this.
    const type = $('.account-type label.active').data('account-type')
    if (type === 'sis_connect') {
      this.setState({
        form_action: '/users/sign_in',
        username_name: 'user[email]',
        username_placeholder: 'Email address',
        password_name: 'user[password]',
        form_on_submit: this.handleConnectSubmit
      });
      $("div.social").hide();
      $("div.non-social").show();
    } else if (type === 'sis') {
      this.setState({
        form_action: '/login',
        username_name: 'username',
        username_placeholder: 'Username',
        password_name: 'password',
        form_on_submit: this.handleSisSubmit
      });
      $("div.social").hide();
      $("div.non-social").show();
    } else if (type === 'social') {
      $("div.social").show();
      $("div.non-social").hide();
    }
  }
  handleSisSubmit = (e) => {
    e.preventDefault();
    $("i#login-spinner").show();
    $("span#login-text").text("Checking...");

    const { url } = this.props;
    const { username, password } = this.state;
    const { target } = e;

    axios.create({
      headers: { 'SIS-API-Access-Token': '5A162F84C5764379784E672C6FBF2075', }
    }).post(url, {
      username: username,
      password: password
    }).then((response) => {
      target.submit();
    }).catch((error) => {
      $(target).find(".form-group").addClass("has-error");
      $(target).find("#login-spinner").hide();
      $(target).find("#login-text").text("Sign in");
    });
  }
  handleConnectSubmit(e) {
    $("i#login-spinner").show();
  }
  render() {
    // All pre-render calculations
    const token = $('meta[name="csrf-token"]').attr('content');

    return (
      <div>
        <div style={{ 'float': 'left' }}>
          <div style={{ 'marginRight': '-15px', 'paddingRight': '0px' }} className="navbar-form navbar-left">
            <div className="btn-group account-type" data-toggle="buttons" onClick={this.handleAccountChange}>
              <label style={{ 'width': '120px' }} className="btn btn-warning active" data-account-type="sis">
                <input type="radio" name="options" id="option1" /> SIS
              </label>
              <label style={{ 'width': '120px' }} className="btn btn-warning" data-account-type="sis_connect">
                <input type="radio" name="options" id="option2" /> SIS Connect
              </label>
              <label style={{ 'width': '120px' }} className="btn btn-warning" data-account-type="social">
                <input type="radio" name="options" id="option3" /> OAuth
              </label>
            </div>
          </div>
          <div style={{ 'width': '590px' }} className="navbar-form navbar-left" >
            <div className="non-social" style={{ 'float': 'left' }}>
              <form action={this.state.form_action} method="POST" onSubmit={this.state.form_on_submit}>
                <input type="hidden" name="authenticity_token" value={token} />
                <div className="form-group">
                  <input className="form-control" type="text" name={this.state.username_name} placeholder={this.state.username_placeholder} value={this.state.username} onChange={this.handleUsernameChange} />
                </div>
                <div className="form-group">
                  <input className="form-control" type="password" name={this.state.password_name} placeholder="Password" value={this.state.password} onChange={this.handlePasswordChange} autoComplete="off" />
                </div>
                <button className="btn btn-success" type="submit">
                  <i id="login-spinner" className="fa fa-spinner fa-pulse fa-fw margin-bottom" style={{ display: 'none' }}></i>
                  <span id="login-text">Sign in</span>
                </button>
              </form>
            </div>
            <div className="social" style={{ 'float': 'left', 'display': 'none' }}>
              <a className="btn btn-social btn-google" href="/auth/google_oauth2"><i className="fa fa-google"></i>Sign in with Google</a>
              <a className="btn btn-social btn-facebook" href="/auth/facebook"><i className="fa fa-facebook"></i>Sign in with Facebook</a>
              <a className="btn btn-social btn-twitter" href="/auth/twitter"><i className="fa fa-twitter"></i>Sign in with Twitter</a>
            </div>
          </div>
          <form style={{ 'paddingLeft': '0px', 'marginLeft': '-15px' }} action="/users/sign_up" className="navbar-form navbar-right" method="GET">
            <button className="btn btn-info" type="submit">
              <span>Register</span>
            </button>
          </form>
        </div>
      </div>
    );
  }
};

export default LoginBox;
