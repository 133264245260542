import React from 'react';

class TaxonFinderLevel extends React.Component {
  isSelectedTaxon = () => {
    var level = this.props.level
    var taxon = this.props.data.taxon
    return taxon != null && taxon.level == level
  }
  isChildrenTaxon = () => {
    var level = this.props.level
    var children = this.props.data.children
    return children != null && children.length != 0 && children[0].level == level
  }
  isParentTaxon = () => {
    var level = this.props.level
    var parents = this.props.data.parents
    return parents != null && this.getParentIndex(level, parents) != -1
  }
  isHardcodedKingdom = () => {
    var level = this.props.level
    var taxon = this.props.data.taxon
    return taxon == null && level == "kingdom"
  }
  handleClick = () => {
    var level = this.props.level
    var parents = this.props.data.parents

    if (this.isSelectedTaxon() || this.isParentTaxon()) {
      if (this.isSelectedTaxon()) {
        // Pluck last element from parent array (if it exists)
        if (parents.length != 0) {
          id = parents[parents.length - 1].id
        } else {
          id = 0
        }
      } else if (this.isParentTaxon()) {
        // Pluck previous element from parent array (if it exists)
        if (this.getParentIndex(level, parents) != 0) {
          id = parents[this.getParentIndex(level, parents) - 1].id;
        } else {
          id = 0
        }
      }

      // Browse for selected taxonomy
      this.props.onSearchSelect(id);
    }
  }
  getParentIndex = (level, parents) => {
    for (i = 0; i < parents.length; i++) {
      if (parents[i].level == level) {
        // We have a match! Return the level
        return i
      }
    }
    // No matches
    return -1;
  }
  buildSelect = (data) => {
    options = data.map(function (kingdom) {
      return (
        <option key={kingdom.id} value={kingdom.id}>
          {kingdom.name}
        </option>
      );
    });

    return (<select className="child-select" style={{ width: '280px' }}>
      <option></option>
      {options}
    </select>)
  }
  buildKingdomSelect = () => {
    kingdoms =
      [
        { id: "100003", name: "ANIMALIA" },
        { id: "13166399", name: "BACTERIA" },
        { id: "100004", name: "CHROMISTA" },
        { id: "100001", name: "FUNGI" },
        { id: "100002", name: "PLANTAE" },
        { id: "65363930", name: "PROTOZOA" }
      ]

    return this.buildSelect(kingdoms)
  }
  buildChildrenSelect = () => {
    var children = this.props.data.children

    return this.buildSelect(children)
  }
  initSelect2 = () => {
    select_widget = $('select.child-select').select2({
      placeholder: "Select a taxon"
    });

    // When a selection is made, let your parent know
    $('select.child-select').on("change", function (e) {
      data = $('select.child-select').select2('data')

      if (data.length == 0) {
        id = "0"
      } else {
        id = data[0].id
      }

      this.props.onSearchSelect(id);
    }.bind(this));
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.isChildrenTaxon() || this.isHardcodedKingdom()) {
      this.initSelect2();
    }
  }
  componentDidMount = () => {
    if (this.isHardcodedKingdom()) {
      this.initSelect2();
    }
  }
  capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  render() {
    var value = ""
    var level = this.props.level
    var taxon = this.props.data.taxon
    var parents = this.props.data.parents
    var children = this.props.data.children

    if (this.isHardcodedKingdom()) {
      // Print hard-coded select box.
      value = this.buildKingdomSelect()
    } else if (this.isSelectedTaxon()) {
      // Selection made at this taxonomic level. Print name.
      value = taxon.name
    } else if (this.isParentTaxon()) {
      // Selection made BELOW this taxonomic level. Print name using parents array.
      parentIndex = this.getParentIndex(level, parents)
      value = parents[parentIndex].name
    } else if (this.isChildrenTaxon()) {
      // Selection made DIRECTLY ABOVE this taxonomic level. Print select box using children array.
      value = this.buildChildrenSelect()
    } else {
      // Either:
      // - No selection made, or
      // - Selection made ABOVE this taxonomic level by more than one level.
      // Print nothing.
      value = ""
    }

    return (
      <div className="taxon-level">
        <div className="name" style={{ float: 'left', width: '120px' }}>
          {this.capitalize(this.props.level)}:
        </div>
        <div onClick={this.handleClick} className="value" style={{ float: 'left' }}>
          {value}
        </div>
        <div style={{ clear: 'both' }} />
      </div>
    );
  }
};

export default TaxonFinderLevel;
