/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import ReactDOM from "react-dom";
import React from "react";
import CommentBox from "../components/CommentBox";

$(document).ready(() => {

    // Load tab content on the fly to reduce page load time
    $('a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
        var filename = $(e.currentTarget).data("filename");
        var ws_id = $('table.taxon-check').data("id"),
            ajax_content = $('div.tab-pane#' + filename + ' .ajax-content');
        //  If the div doesn't exist, this suggests AJAX is disabled. Nothing to do.
        if (!ajax_content.length) {
            console.log("AJAX disabled.");
            return;
        }
        var loaded = ajax_content.attr("data-loaded");
        //  Has this tab already been populated ? If so, there is nothing to do.
        if (loaded == "true") {
            console.log("Already loaded.");
            return;
        }

        ajax_content.load('/ajax/content?filename=' + filename + '&id=' + ws_id, () => {
            console.log("Load complete.");
            //  Ensure data is not reloaded in future
            ajax_content.attr("data-loaded", "true");

            //  Initialise DataTable
            var new_table = ajax_content.find('table.file-contents');
            new_table.DataTable({
                "lengthMenu": [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
                "pageLength": 100,
                dom: 'B<"clear">lfrpitip',
                buttons: [
                    'print',
                    'copyHtml5',
                    'excelHtml5',
                    'csvHtml5',
                    'pdfHtml5'
                ]
            });
            new_table.addClass('table table-bordered table-condensed responsive');

            //  Initialise tooltips
            $('.show-id[data-tooltip!=""]').qtip({
                content: {
                    attr: 'data-tooltip'
                },
                style: {
                    classes: 'qtip-youtube qtip-shadow qtip-rounded'
                },
                position: {
                    target: 'mouse',
                    adjust: {
                        x: 1,
                        y: 1
                    }
                },
                show: {
                    delay: 1000
                }
            });
            //  Initialise comments section
            var comments_section = ajax_content.find('div[data-react-class="CommentBox"]'),
                comments_props = JSON.parse(comments_section.attr('data-react-props'));
            ReactDOM.render(React.createElement(CommentBox, comments_props), comments_section[0]);
        });
    });


    //  Place all the behaviors and hooks related to the matching controller here.
    // All this logic will automatically be available in application.js.
    //  You can use CoffeeScript in this file: http://coffeescript.org/

    var forums_table = $('table#forums-table').DataTable({
        'columnDefs': [
            {
                'targets': [0],
                'visible': false
            }
        ]
    });

    var member_forums_table = $('table#member-forums-table').DataTable({
        'columnDefs': [
            {
                'targets': [0],
                'visible': false
            }
        ]
    });

    $('table#forum-users-table').DataTable({
        'columnDefs': [
            {
                'targets': [0],
                'visible': false
            }
        ]
    });

    $('table#arrangements-table').DataTable();
    $('table#forum-working-sets-table').DataTable();
    $('table#polygons-table').DataTable();

    $('table#forums-table tbody').on('click', 'tr', (e) => {
        if (!(event.target.nodeName == 'BUTTON' || event.target.nodeName == 'A')) {
            var forum_id = forums_table.row(e.currentTarget).data()[0];
            window.location.href = "/forums/" + forum_id;
            return;
        }
    });

    $('table#member-forums-table tbody').on('click', 'tr', (e) => {
        if (!(event.target.nodeName == 'BUTTON' || event.target.nodeName == 'A')) {
            var forum_id = member_forums_table.row(e.currentTarget).data()[0];
            window.location.href = "/forums/" + forum_id;
            return;
        }
    });

    //    Initialise Select2 widgets
    $('select.sis-user-select').select2();
    $('select.email-user-select').select2({ tags: true });
    $('select.working-set-select').select2();
    $('select.arrangement-select').select2();

    //    This callback function is used by WsBrowser.
    //    TODO: This might not be good practice.
    //    TODO: We need to consider how this behaves when requests stack up. Loading
    //    happens invisibly, bad user experience.
    window.onAssessmentLoad = (assessmentid, taxonid, scientific_name, ws_id, apiToken, forumid, force_refresh) => {
        var content = $("#assessment-content");

        //  Before loading, show the spinner and hide the current content
        $("#assessment-spinner").show();
        content.hide();

        var params = {
            ws_id: ws_id,
            assessment_id: assessmentid,
            api_key: apiToken,
            taxon_id: taxonid,
            forum_id: forumid,
            scientific_name: scientific_name,
            force_refresh: force_refresh
        };

        //  AJAX load content
        content.load("/load_assessment", params, () => {
            //    Initialise datatables
            var cs_tables = content.find('table.cs-table');
            cs_tables.DataTable();
            cs_tables.addClass('table table-bordered table-condensed responsive');
            cs_tables.width('100%');

            //    Initialise assessments comments section
            var comments_section = content.find('div[data-react-class="CommentBox"]');
            var comments_props = JSON.parse(comments_section.attr('data-react-props'));
            ReactDOM.render(React.createElement(CommentBox, comments_props), comments_section[0]);

            //    Setup hard refresh click handler
            $('#refresh-assessment').on('click', (e) => {
                var self = e.currentTarget;
                onAssessmentLoad(
                    $(self).data('aid'), $(self).data('tid'),
                    $(self).data('tname'), $(self).data('wsid'),
                    $(self).data('token'), $(self).data('fid'), $(self).data('refresh'));
            });
            //    Setup comment filter click handlers
            $('.filter-comments').on('click', (e) => {
                if ($(e.currentTarget).hasClass('active')) {
                    //    Untoggle
                    var fieldname = '';
                } else {
                    //    Toggle
                    var fieldname = $(e.currentTarget).data('fieldname');
                    $('.filter-comments').removeClass('active')
                }
                var comments_section = $('div[data-react-class="CommentBox"]');
                var comments_props = JSON.parse(comments_section.attr('data-react-props'));
                //  Update fieldname
                comments_props["filter"] = fieldname;
                //  Unmount old component
                ReactDOM.unmountComponentAtNode(comments_section[0]);
                //  Mount new component
                ReactDOM.render(React.createElement(CommentBox, comments_props), comments_section[0]);
            });


            //    Hide the spinner
            $("#assessment-spinner").hide();
            content.show();

            //    Render map, if applicable
            if (polys.length > 0) {
                var mymap = new (L.Map)('mapid');
                var osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
                var osmAttrib = 'Map data © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors';
                var osm = new (L.TileLayer)(osmUrl, {
                    minZoom: 1,
                    maxZoom: 12,
                    attribution: osmAttrib
                });
                //  Start the map in South-East England
                mymap.setView(new (L.LatLng)(51.3, 0.7), 9);
                mymap.addLayer(osm);
                myFeatureGroup = L.featureGroup();
                var mycolor = {};
                legends.forEach((l) => {
                    mycolor[l] = '#' + Math.random().toString(16).substr(2, 6);
                    return;
                });
                var i = 0;
                while (i < polys.length) {
                    myLayer = omnivore.wkt.parse(polys[i].geom)
                    myLayer.setStyle({
                        color: mycolor[polys[i].attribs['LEGEND']],
                        fillColor: mycolor[polys[i].attribs['LEGEND']],
                        opacity: 1,
                        weight: 1.5,
                        fillOpacity: 0.2
                    });
                    myLayer.bindPopup('Legend : ' + polys[i].attribs['LEGEND']);
                    myFeatureGroup.addLayer(myLayer);
                    i++
                }
                myFeatureGroup.addTo(mymap);
                mymap.fitBounds(myFeatureGroup.getBounds());
                return;
            };
            return;
        });

        if (typeof (arrangement) != "undefined") {
            //  Arrangement tree
            //  TODO: Ensure root cannot be deleted
            //  TODO: Ensure root cannot have siblings
            $('#arrangement-tree').jstree({
                'plugins': ["dnd", "contextmenu", "types"],
                'types': {
                    'default': {
                        'icon': 'fa fa-folder-o'
                    },
                    'leaf': {
                        'icon': 'fa fa-file',
                        'max_children': 0
                    },
                    'core': {
                        'check_callback': true,
                        'data': JSON.parse(arrangement)
                    }
                }
            });

            //  Fields tree
            $('#fields-tree').jstree({
                'plugins': ["dnd", "types"],
                'types': {
                    'default': {
                        'icon': 'fa fa-folder-o'
                    },
                    'leaf': {
                        'icon': 'fa fa-file',
                        'max_children': 0
                    }
                }
            });
        }

        $('#arrangement-form').submit(() => {
            //  Populate hidden "arrangement" field to store the JSON tree representation
            var arrangement = $('#arrangement-tree').jstree(true).get_json('#');
            $('#arrangement-form input[name="arrangement"]').val(JSON.stringify(arrangement));
        });

    };
});
