import React from 'react';


class WSBrowserAssessmentTable extends React.Component {
  componentDidMount = () => {
    var url;
    var dataSrc;
    var ws_id = this.props.ws.id;

    if (this.props.forumMode) {
      url = '/api/assessments?forum_id=' + this.props.forumId + '&token=' + this.props.apiToken;

      dataSrc = function (json) {
        return json.assessments;
      }
    } else {
      url = this.props.apiBase + '/workingsets/' + this.props.ws.id + '/assessments?key=' + this.props.apiToken;

      dataSrc = function (json) {
        var return_data = new Array();
        // We need to manually add "ws_id" field, as the API does not return this.
        for (var i = 0; i < json.assessments.length; i++) {
          return_data.push({
            'assessmentid': json.assessments[i].assessmentid,
            'taxonid': json.assessments[i].taxonid,
            'ws_id': ws_id,
            'name': json.assessments[i].name,
            'type': json.assessments[i].type
          })
        }
        return return_data;
      }
    }

    var table = $('table#assessment-table').DataTable({
      destroy: true,
      ajax: {
        url: url,
        dataSrc: dataSrc
      },
      columns: [
        { data: 'assessmentid' },
        { data: 'taxonid' },
        { data: 'ws_id' },
        { data: 'name' },
        { data: 'type' }
      ],
      columnDefs: [
        {
          'targets': [0, 1, 2],
          'visible': false
        }
      ],
      "bPaginate": false,
      "dom": '<lfi<t>ip>'
    });

    // This function has to be defined outside to be accessible in the event
    // handler.
    var onSelectAssessment = this.props.onSelectAssessment,
      forumMode = this.props.forumMode,
      forumId = this.props.forumId,
      onAssessmentLoad = this.props.onAssessmentLoad,
      apiToken = this.props.apiToken;

    $('table#assessment-table tbody').on('click', 'tr', function () {
      var assessmentid = table.row(this).data().assessmentid,
        name = table.row(this).data().name,
        taxonid = table.row(this).data().taxonid,
        type = table.row(this).data().type,
        ws_id = table.row(this).data().ws_id,
        force_refresh = false;

      if (forumMode) {
        onAssessmentLoad(assessmentid, taxonid, name, ws_id, apiToken, forumId, force_refresh)
      } else {
        var assessment = { id: assessmentid, scientific_name: name, taxon_id: taxonid, type: type }
        onSelectAssessment(assessment)
      }
    });
  }
  render() {
    return (
      <div className="ws-browser-assessment-table">
        <table id="assessment-table" width="100%" className="table table-bordered table-condensed responsive">
          <thead>
            <tr>
              <th>Assessment ID</th>
              <th>Taxon ID</th>
              <th>Working set ID</th>
              <th>Scientific name</th>
              <th>Type</th>
            </tr>
          </thead>
        </table>
      </div>
    );
  }
};

export default WSBrowserAssessmentTable;
