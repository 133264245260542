import React from 'react';
import TaxonFinderLevel from './TaxonFinderLevel'

class TaxonFinderTree extends React.Component {
  render() {
    var ranks = ["kingdom", "phylum", "class", "order", "family", "genus", "species", "infrarank", "subpopulation"]
    var taxonLevels = ranks.map(function (rank) {
      return (
        <TaxonFinderLevel onSearchSelect={this.props.onSearchSelect} data={this.props.data} key={rank} level={rank}></TaxonFinderLevel>
      );
    }.bind(this));
    return (
      <div className="taxon-levels">
        {taxonLevels}
      </div>
    );
  }
};

export default TaxonFinderTree;
