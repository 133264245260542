import React from "react";

class WorkingSetActions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            working_set: props.working_set,
            current_user: props.current_user,
            form_authenticity_token: props.form_authenticity_token
        };
    }


    render() {

        return (
            <div className="dropdown">
                <button aria-expanded="true" aria-haspopup="true" className="btn btn-info dropdown-toggle" data-toggle="dropdown" id={`#dropdownMenu${this.state.working_set.id}`} type="button">Actions <span className="caret"></span></button>
                <ul aria-labelledby={`dropdownMenu${this.state.working_set.id}`} className="dropdown-menu">


                    {/* // ACTIONS - UPLOADER */}
                    {(this.state.working_set.user_id === this.state.current_user.id || this.state.current_user.is_redlist) &&

                        <React.Fragment>
                            {
                                (this.state.working_set.public === true) ?
                                    (<li><a href={`/working_sets/${this.state.working_set.id}/make_public?public=false`}>Make public</a></li>)
                                    :
                                    (<li><a href={`/working_sets/${this.state.working_set.id}/make_public?public=true`}>Make public</a></li>)
                            }

                            {(this.state.working_set.upload_failed === true || this.state.working_set.ready_for_import === true || this.state.working_set.redlist_check === true || this.state.working_set.validation_passed_with_warnings === true || this.state.working_set.validation_passed === true || this.state.working_set.validation_failed === true) &&
                                <li><a data-confirm="When you re-upload a working set, all data is replaced. This action is not reversible. Are you sure you wish to continue?" href={`/working_sets/${this.state.working_set.id}/edit`}>Re-upload</a></li>
                            }

                            {(!(this.state.working_set.upload_failed === true || this.state.working_set.new === true || this.state.working_set.sis_import_passed === true)) &&
                                <React.Fragment>
                                    {(this.state.working_set.taxonomy_being_validated === true || this.state.working_set.redlist_check_failed === true) ?
                                        (<li className="disabled"><a href="#">Validate taxonomy</a></li>)
                                        :
                                        (<li><a href={`/working_sets/${this.state.working_set.id}/validate_species`}>Validate taxonomy</a></li>)
                                    }

                                    {(this.state.working_set.records_being_validated === true || this.state.working_set.redlist_check_failed === true) ?
                                        (<li className="disabled"><a href="#">Validate records</a></li>)
                                        :
                                        (<li><a href={`/working_sets/${this.state.working_set.id}/validate_records`}>Validate records</a></li>)
                                    }
                                </React.Fragment>
                            }

                            {(this.state.working_set.validation_failed === true || this.state.working_set.validation_passed === true || this.state.working_set.validation_passed_with_warnings === true) &&
                                <li><a href={`/working_sets/${this.state.working_set.id}/submit`}>Submit to Red List Unit</a></li>
                            }

                            <li><a className="left-tooltip" data-tooltip={this.state.working_set.attachment_identifier} href={this.state.working_set.attachment_url} data-hasqtip="543"> Download zip</a></li>

                            {(this.state.current_user.is_admin === false) &&
                                // NOTE: this does not actually delete the working set, only archives it.
                                <li className="disabled"><a href="#archive_working_set_path" dataConfirm="Are you sure?">Validate records</a></li>

                            }
                        </React.Fragment>
                    }


                    {/* // ACTIONS - RED LIST UNIT */}
                    {(this.state.current_user.is_redlist === true) &&
                        <React.Fragment>
                            <li className="divider" role="separator" />
                            <li><a aria-controls="collapseExample" aria-expanded="false" data-toggle="collapse" href={`#reassign${this.state.working_set.id}`} role="button">Re-assign user</a></li>

                            {(this.state.working_set.redlist_check === true) &&
                                <React.Fragment>
                                    <li><a href={`/working_sets/${this.state.working_set.id}/redlist_check?accept=true`}>RL Check: Accept</a></li>
                                    <li><a href={`/working_sets/${this.state.working_set.id}/redlist_check?accept=false`}>RL Check: Reject</a></li>

                                </React.Fragment>
                            }
                        </React.Fragment>
                    }


                    {/* // ACTIONS - ADMIN */}
                    {(this.state.current_user.is_admin === true) &&
                        <React.Fragment>

                            <li className="divider" role="separator" />
                            <li><a href={`/sis/v1/sets/${this.state.working_set.id}/taxonomy`} target="_blank">View API</a></li>

                            {/* // NOTE: Hard delete! */}
                            <li>
                                {/* <a data-confirm="Are you sure? This will HARD DELETE the working set, NOT archive it." className="btn btn-danger btn-xs ws-delete" data-remote="false" rel="nofollow" data-method="delete" href={`/working_sets/${this.state.working_set.id}`}>Delete</a> */}
                                <a>
                                <form method="post" action={`/working_sets/${this.state.working_set.id}`} onSubmit={(e) => {if(!confirm('Are you sure? This will HARD DELETE the working set, NOT archive it.')){e.preventDefault();}}}>
                                    <input name="authenticity_token" type="hidden" value={this.state.form_authenticity_token} />
                                    <input type="hidden" value="delete" name="_method"/>
                                    <button className="btn btn-danger btn-xs ws-delete btn-block" type="submit" >Delete</button>
                                </form>
                                </a>
                            </li>
                        </React.Fragment>

                    }

                </ul>
            </div>
        );
    }
}

export default WorkingSetActions;
