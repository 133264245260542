// Props:
// apiBase - The base URL for the SIS API
// forumMode - TRUE if working sets should be retrieved by forum. FALSE if
// working sets should be retrieved by user.
// apiToken - The SIS API token to use when making requests. This will belong to
// the forum owner when forumMode = TRUE
// forumId - Set when forumMode = TRUE
// callbackUrl - Where the output should be POSTed
import React from 'react';
import WSBrowserWSTable from './WSBrowserWSTable';
import WSBrowserBct from './WSBrowserBct';
import WSBrowserAssessmentTable from './WSBrowserAssessmentTable';
import WSBrowserAssessment from './WSBrowserAssessment';

class WSBrowser extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ws: { id: 0, name: "" },
      assessment: { id: 0, scientific_name: "", taxon_id: 0, type: "" }
    };
  }
  handleSelectWS = (ws) => {
    this.setState({
      ws: ws
    })
  }
  handleDeselectWS = () => {
    this.setState({
      ws: { id: 0, name: "" },
      assessment: { id: 0, scientific_name: "", taxon_id: 0, type: "" }
    })
  }
  handleSelectAssessment = (assessment) => {
    this.setState({
      assessment: assessment
    })
  }
  handleDeselectAssessment = () => {
    this.setState({
      assessment: { id: 0, scientific_name: "", taxon_id: 0, type: "" }
    })
  }
  render() {
    wsTable =
      <WSBrowserWSTable
        apiToken={this.props.apiToken}
        apiBase={this.props.apiBase}
        forumMode={this.props.forumMode}
        forumId={this.props.forumId}
        onSelectWS={this.handleSelectWS} />
    assessmentTable =
      <WSBrowserAssessmentTable
        apiToken={this.props.apiToken}
        apiBase={this.props.apiBase}
        onSelectAssessment={this.handleSelectAssessment}
        onAssessmentLoad={window.onAssessmentLoad}
        forumMode={this.props.forumMode}
        forumId={this.props.forumId}
        ws={this.state.ws} />
    assessment =
      <WSBrowserAssessment
        apiToken={this.props.apiToken}
        apiBase={this.props.apiBase}
        assessment={this.state.assessment}
        ws={this.state.ws}
        callbackUrl={this.props.callbackUrl} />

    var current_view;
    if (this.state.assessment.id != 0) {
      current_view = assessment
    } else if (this.state.ws.id != 0 || this.props.forumMode) {
      current_view = assessmentTable
    } else {
      current_view = wsTable
    }
    var bct;
    if (this.props.forumMode) {
      bct = null
    } else {
      bct = <WSBrowserBct
        ws={this.state.ws}
        assessment={this.state.assessment}
        onDeselectWs={this.handleDeselectWS}
        onDeselectAssessment={this.handleDeselectAssessment} />
    }

    return (
      <div className="ws-browser">
        <h2>Browser</h2>
        <div className="ws-browser-bct">
          {bct}
        </div>
        {current_view}
      </div>
    );
  }
}

export default WSBrowser;

