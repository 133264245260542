import React from "react";

class WorkingSetExportButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      form_authenticity_token: props.form_authenticity_token,
      working_set: props.working_set,
      main_files: props.main_files,
      class_files: props.class_files,
      other_files: props.other_files,
    };
  }

  render() {
    // Prevent multi-region "OR" working sets from being exported
    if (this.state.working_set["relationship"] == "or" && this.state.working_set["regions"].length > 1) {
      return (
        <button className="btn disabled left-tooltip" style={{ borderColor: "#a51d13;" }} type="button" data-tooltip="Sorry, multi-region working sets with the 'or' relationship cannot be exported.">Export</button>
      );
    } else {
      return (
        <form className="new_sis_export" accept-charset="UTF-8" action="/sis_exports" enctype="multipart/form-data" method="post">
          <input name="utf8" type="hidden" value="✓" />
          <input name="authenticity_token" type="hidden" value={this.state.form_authenticity_token} />
          <input id="sis_export_ws_id" name="sis_export[ws_id]" type="hidden" value={this.state.working_set.id} />
          <input id="sis_export_name" name="sis_export[name]" type="hidden" value={this.state.working_set.name} />

          <div className="btn-group" role="group" style={{ width: "100px" }}>
            <button type="submit" className="btn btn-danger export-button" style={{ borderColor: "#a51d13;" }}>Export</button>
            <button type="button" className="btn btn-danger dropdown-toggle" style={{ borderColor: "#a51d13;" }} aria-expanded="false" aria-haspopup="true" data-toggle="dropdown">
              <span className="caret"></span>
              <span className="sr-only">Toggle Dropdown</span>
            </button>
            <ul className="dropdown-menu dropdown-menu-right">
              <li>
                <a data-target={"#advanced-modal-ws_" + this.state.working_set.id} data-toggle="modal" href="#">Advanced export</a>
              </li>
            </ul>

            <div className="modal fade" id={"advanced-modal-ws_" + this.state.working_set.id} role="dialog" tabindex="-1">
              <div className="modal-dialog modal-sm" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button">
                      <span aria-hidden="true" dangerouslySetInnerHTML={{ __html: '\u00D7' }} />
                    </button>
                    <h4 className="modal-title">Advanced export</h4>
                  </div>

                  <div className="modal-body">
                    <h5>Main files</h5>
                    <div className="main-files">
                      {this.state.main_files.map((file, index) => {
                        return (<div className="checkbox" style={{ display: "block" }}><label><input checked="checked"
                          name="advanced[{file}]" type="checkbox" value="" />{file}</label></div>)
                      })}
                    </div>
                    <h5>Classification scheme files</h5>
                    <div className="className-files">
                      {this.state.class_files.map((file, index) => {
                        return (<div className="checkbox" style={{ display: "block" }}><label><input checked="checked"
                          name="advanced[{file}]" type="checkbox" value="" /> {file}</label></div>)
                      })}
                    </div>

                    <h5>Other files</h5>
                    <div className="other-files">
                      {this.state.other_files.map((file, index) => {
                        return (<div className="checkbox" style={{ display: "block" }}><label><input checked="checked"
                          name="advanced[{file}]" type="checkbox" value="" />{file}</label></div>)
                      })}
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button className="btn btn-default advanced-cancel" data-dismiss="modal" type="button"> Close</button>
                    <button className="btn btn-primary export-button" type="submit"> Export</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      );
    }
  }
}

export default WorkingSetExportButton;
