import React from 'react';
import Axios from "axios"
class WSBrowserAssessment extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      assessment_date: "",
      category: ""
    };
  }
  componentDidMount = () => {
    Axios.get(this.props.apiBase + '/assessments/' + this.props.assessment.id + '/details?key=' + this.props.apiToken)
      .then((response) => {
        this.setState({
          category: response.data.details.RedListCategory,
          assessment_date: response.data.details.RedListAssessmentDate
        });
      }).catch((error) => {
        console.error(error);
      });
  }
  render() {
    const token = $('meta[name="csrf-token"]').attr('content');

    return (
      <div>
        <div className="well well-lg">
          <p style={{ fontSize: "20px" }}>Assessment {this.props.assessment.id}</p>
          <p>Type: <b>{this.props.assessment.type}</b></p>
          <p>Species: <b>{this.props.assessment.scientific_name}</b></p>
          <p>Category: <b>{this.state.category}</b></p>
          <p>Assessment date: <b>{this.state.assessment_date}</b></p>
          <form action={this.props.callbackUrl} method="post">
            <input type="hidden" name="ws_id" value={this.props.ws.id} />
            <input type="hidden" name="taxon_id" value={this.props.assessment.taxon_id} />
            <input type="hidden" name="assessment_id" value={this.props.assessment.id} />
            <input type="hidden" name="api_key" value={this.props.apiToken} />
            <input type="hidden" name="authenticity_token" value={token} />
            <button type="submit" className="btn btn-success btn-lg">Load assessment</button>
          </form>
        </div>
      </div>
    );
  }
};

export default WSBrowserAssessment;
