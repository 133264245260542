import { connect } from 'react-redux';
import Toggle from 'react-bootstrap-toggle';
import React from "react";


class ShowArchivedWorkingSets extends React.Component {
    constructor(props) {
        super(props);
        this.state = { toggleActive: false }
    }
    handleChange = () => {
        const new_state_active = !this.state.toggleActive;
        this.setState({ toggleActive: new_state_active });
        if (new_state_active) {
            this.props.checked();
        } else {
            this.props.unckecked();
        }
    }
    render() {
        return (
            <div className="checkbox">
                <label>
                    <Toggle onClick={this.handleChange} size={'sm'} active={this.state.toggleActive} />
                    Show archived working sets
            </label>
            </div>);
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        checked: () => { dispatch({ type: 'SHOW_ARCHIVED' }) },
        unckecked: () => { dispatch({ type: 'NON_ARCHIVED' }) }
    }
};

export default connect(null, mapDispatchToProps)(ShowArchivedWorkingSets);
